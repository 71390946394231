<template>
  <div>
    <b-card no-body class="card-developer-meetup rounded-0">
      <div class="bg-light-primary rounded-top text-center rounded-0 overflow-auto">
        <b-img :src="handlerLogoShow(item)" :alt="item.icerik['tr'].baslik" height="170" />
        <b-avatar
          variant="light-success"
          rounded
          class="updateButton rounded-0 cursor-pointer"
          v-b-tooltip.hover.top="'Ayarlar'"
          size="48"
        >
          <feather-icon icon="SettingsIcon" size="28" @click="handlerUpdate(item)" />
        </b-avatar>
      </div>

      <b-card-body>
        <!-- metting header -->
        <div class="meetup-header d-flex align-items-center">
          <div class="meetup-day">
            <h6 class="mb-0">
              <b-avatar
                variant="light-info"
                rounded
                class="rounded-0"
                size="48"
                v-b-tooltip.hover.top="'Üst Slider'"
                v-if="item.konum == 'top'"
              >
                <feather-icon icon="UploadCloudIcon" size="28" />
              </b-avatar>
              <b-avatar variant="light-danger" rounded class="rounded-0" size="48" v-b-tooltip.hover.top="'Alt Slider'" v-else>
                <feather-icon icon="DownloadCloudIcon" size="28" />
              </b-avatar>
            </h6>
          </div>
          <div class="my-auto">
            <b-card-title class="mb-25"> {{ item.icerik['tr'].baslik }} </b-card-title>
            <b-card-text class="mb-0">{{ item.icerik['tr'].aciklama | str_limit(25) }} </b-card-text>
          </div>
        </div>
        <!--/ metting header -->

        <!-- media -->
        <div class="d-flex justify-content-between align-item-center">
          <b-media no-body>
            <b-media-aside>
              <b-avatar rounded class="rounded-0" variant="light-primary" size="34">
                <feather-icon icon="CalendarIcon" size="18" />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="mb-0">
                {{ item.save_date | moment }}
              </h6>
              <small>{{ item.save_date | momentTime }}</small>
            </b-media-body>
          </b-media>
          <b-media no-body class="mt-0">
            <b-media-aside v-for="(lang, i) in item.icerik" :key="i">
              <b-avatar rounded class="rounded-0" :variant="langColor(lang)" size="28"> {{ i }} </b-avatar>
            </b-media-aside>
          </b-media>
        </div>
      </b-card-body>
    </b-card>
    <slider-update :openModal="openModal" :updateData="updateData" @closeModal="closeModal = $event" />
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api';
import SliderUpdate from './sliderUpdate.vue';
import store from '@/store';
export default {
  components: {
    SliderUpdate,
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: {},
    },
  },

  setup() {
    const expo = {};
    expo.openModal = ref(false);
    expo.closeModal = ref(false);
    expo.updateData = ref({});
    const fileName = store.getters.getUserFirma.db;

    expo.handlerLogoShow = computed(() => {
      return (val) => {
        if (val.gorsel != undefined) {
          const base_url =
            process.env.NODE_ENV === 'development'
              ? process.env.VUE_APP_DEV_BASEURL.slice(0, -3)
              : process.env.VUE_APP_PRO_BASEURL.slice(0, -3);

          return `${base_url}uploads/${fileName}/slider/${val.gorsel}`;
        }
      };
    });

    expo.langColor = (value) => {
      return value.baslik != null ? 'success' : 'danger';
    };

    expo.handlerUpdate = (item) => {
      expo.updateData.value = item;
      expo.openModal.value = true;
    };

    return { ...expo };
  },
  watch: {
    closeModal(val) {
      this.openModal = false;
      this.closeModal = false;
      this.$emit('refresh', val);
      this.updateData = {};
    },
  },
};
</script>

<style lang="scss">
.updateButton {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #b9b9c32e;
  padding: 10px;
}
</style>
