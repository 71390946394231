<template>
  <div>
    <b-card-actions
      ref="cardAction"
      title="Galeri"
      icon="ImageIcon"
      :showLoading="show"
      :actionCollapse="false"
      :actionRefresh="false"
      :actionNewAdd="true"
      :collapsed="false"
      @refresh="refreshStop('cardAction')"
      @newAdd="handlerNewAdd($event)"
    >
      <template v-slot:body>
        <div v-for="(item, i) in data" :key="i">
          <b-list-group-item
            class="d-flex justify-content-between align-items-center mb-25 p-0 px-1"
            :class="item.statu ? ' border-primary' : ' border-danger'"
          >
            <h6 class="mt-25 d-block" style="cursor: pointer" @click="handlerUpdate(item)">{{ item.icerik['tr'].baslik }}</h6>
            <div class="d-flex justify-content-between align-items-center">
              <ul class="unstyled-list list-inline pt-1 pr-2">
                <li v-for="(lang, index) in item.icerik" :key="index" class="ratings-list-item">
                  <b-button squared :variant="langColor(item.icerik[index])" size="sm" v-b-tooltip.hover.bottom="`${index}`">
                    {{ index }}
                  </b-button>
                </li>
              </ul>
              <div>
                <b-button-group>
                  <b-button
                    squared
                    size="sm"
                    variant="warning"
                    v-b-tooltip.hover.top="'Resimler'"
                    @click="$emit('updateToImages', item)"
                  >
                    <feather-icon icon="CameraIcon" />
                  </b-button>
                  <b-button squared size="sm" variant="danger" v-b-tooltip.hover.top="'Sil'" @click="handlerRemove(item.k_no)">
                    <feather-icon icon="Trash2Icon" />
                  </b-button>
                </b-button-group>
              </div>
            </div>
          </b-list-group-item>
        </div>
      </template>
    </b-card-actions>
    <galeri-tanimla
      :openModal="openModal"
      :updateData="updateData"
      :kategoriler="kategoriler"
      @closeModal="closeModal = $event"
    />
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { ref, watch } from '@vue/composition-api';
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue';
import GaleriTanimla from './galeriTanimla.vue';
import store from '@/store';

import { useToast } from 'vue-toastification/composition';
import { POSITION } from 'vue-toastification';
import Swal from 'sweetalert2';
export default {
  components: {
    BCardActions,
    GaleriTanimla,
  },
  setup() {
    const expo = {};
    const toast = useToast();
    expo.show = ref(false);
    expo.openModal = ref(false);
    expo.closeModal = ref(false);
    expo.updateData = ref({});
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.kategoriler = ref([]);
    expo.data = ref([]);

    expo.handlerNewAdd = () => {
      expo.openModal.value = true;
    };
    expo.handlerUpdate = (event) => {
      expo.openModal.value = true;
      expo.updateData.value = event;
    };

    expo.langColor = (value) => {
      return value.baslik != null ? 'flat-success' : 'flat-danger';
    };

    expo.handlerGetData = async () => {
      expo.show.value = true;
      expo.kategoriler.value = [];
      await store.dispatch('kategoriListele');
      const kat = await store.getters.getKategoriler;

      await store.dispatch('galeriListele');
      expo.data.value = await store.getters.getGaleriler;

      kat.forEach((el) => {
        expo.kategoriler.value.push({ k_no: el.k_no, baslik: el.icerik[expo.defaultDil.value].baslik });
      });
      expo.show.value = false;
    };

    expo.handlerGetData();

    expo.handlerRemove = (k_no) => {
      Swal.fire({
        title: 'Uyarı',
        text: 'Kayit Silinecektir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          store.dispatch('galeriSil', k_no).then((res) => {
            if (res.data.success === true) {
              toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: `Silme`,
                    icon: 'CheckSquareIcon',
                    variant: 'danger',
                    text: 'Silme Başarılı',
                  },
                },
                { position: POSITION.BOTTOM_RIGHT }
              );
            }
          });
        }
      });
    };

    watch(
      expo.closeModal,
      (val) => {
        if (val == false) {
          expo.openModal.value = false;
          expo.closeModal.value = false;
        }
      },
      { deep: true }
    );

    return { ...expo };
  },
};
</script>

<style lang="scss" scoped></style>
