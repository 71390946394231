import { render, staticRenderFns } from "./galeriTanimla.vue?vue&type=template&id=113be77b&scoped=true&"
import script from "./galeriTanimla.vue?vue&type=script&lang=js&"
export * from "./galeriTanimla.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "113be77b",
  null
  
)

export default component.exports