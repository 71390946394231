<template>
  <div>
    <b-modal
      @show="showModal"
      @hidden="hideModal"
      id="my-modal"
      ref="my-modal"
      size="lg"
      class="rounded-0"
      centered
      title="Slider Güncelle"
    >
      <b-card-text>
        <b-row>
          <b-col cols="12" md="8">
            <validation-provider name="Başlık" :rules="{ required: true }" v-slot="validationContext">
              <b-form-group label="Başlık">
                <b-form-input
                  class="rounded-0"
                  ref="baslik"
                  v-model="form.icerik[defaultDil].baslik"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback id="input-1-live-feedback">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group label="Dil">
              <v-select
                v-model="defaultDil"
                :options="diller"
                :reduce="(diller) => diller.lang"
                label="title"
                :clearable="false"
                :disabled="!form._id"
                class="invoice-filter-select d-block"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Açıklama">
              <b-form-textarea class="rounded-0" ref="baslik" v-model="form.icerik[defaultDil].aciklama" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            <b-form-group label="Konum" description="Nerede gösterileceğini seç">
              <v-select
                v-model="form.konum"
                :options="konumlar"
                :reduce="(konum) => konum.id"
                label="title"
                :clearable="true"
                class="invoice-filter-select d-block"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Yeni Sekme" description="Tıklandıgında yeni sekmede aç">
              <v-select
                v-model="form.sekme"
                :options="sekmeler"
                :reduce="(sekme) => sekme.id"
                label="title"
                :clearable="true"
                class="invoice-filter-select d-block"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Statu" description="Gösterim durumu">
              <v-select
                v-model="form.statu"
                :options="statuler"
                :reduce="(statu) => statu.id"
                label="title"
                :clearable="true"
                class="invoice-filter-select d-block"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-text>
      <template #modal-footer>
        <div class="w-100">
          <b-button squared variant="primary" class="float-right" type="submit" @click="onSubmit" :disabled="show">
            <feather-icon class="cursor-pointer" icon="SaveIcon" size="16" v-if="!show" />
            <b-spinner class="mr-25" small variant="light" v-else />
            Güncelle
          </b-button>
          <b-button squared variant="danger" class="float-right mr-1" @click="handlerRemove(form.k_no)">
            <feather-icon class="cursor-pointer" icon="Trash2Icon" size="16" />
            Sil
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import vSelect from 'vue-select';
import { diller } from '@core/mixins/ui/diller';

import { ref, toRefs, watch, computed } from '@vue/composition-api';
import store from '@/store';
import { useToast } from 'vue-toastification/composition';
import { POSITION } from 'vue-toastification';
import Swal from 'sweetalert2';

export default {
  components: {
    vSelect,
  },
  props: {
    openModal: {
      type: Boolean,
      required: true,
    },
    updateData: {
      type: Object,
      required: true,
      default: {},
    },
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();
    expo.diller = computed(() => store.getters.getDilAyarlari.diller);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);

    expo.show = ref(false);
    expo.konumlar = ref([
      { id: 'top', title: 'Üst Slider' },
      { id: 'bottom', title: 'Alt Slider' },
    ]);
    expo.sekmeler = ref([
      { id: 0, title: 'Hayır' },
      { id: 1, title: 'Evet' },
    ]);
    expo.statuler = ref([
      { id: true, title: 'Aktif' },
      { id: false, title: 'Pasif' },
    ]);
    expo.form = ref({
      _id: null,
      icerik: {},
      url: '',
      konum: 'top',
      sekme: 0,
      statu: true,
    });

    const createIcerikDilUret = () => {
      expo.diller.value.forEach((dil) => {
        expo.form.value.icerik[dil.lang] = { baslik: null };
      });
    };
    createIcerikDilUret();
    expo.showModal = () => {
      console.log(context);
      context.refs['my-modal'].show();
    };
    expo.hideModal = () => {
      context.refs['my-modal'].hide();
      context.emit('closeModal', true);
    };
    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };
    expo.onSubmit = () => {
      expo.show.value = true;
      store
        .dispatch('slideGuncelle', expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            toast(
              {
                component: ToastificationContent,
                props: {
                  title: `Bilgi`,
                  icon: 'CheckSquareIcon',
                  variant: 'success',
                  text: 'Güncelleme Başarılı',
                },
              },
              { position: POSITION.BOTTOM_RIGHT }
            );
            expo.show.value = false;
          }
        })
        .catch((err) => {
          expo.show.value = false;
          if (err.success == false) {
            toast(
              {
                component: ToastificationContent,
                props: {
                  title: `Hata`,
                  icon: 'CheckSquareIcon',
                  variant: 'danger',
                  text:
                    Object.keys(err.data.dup).length > 0
                      ? Object.keys(err.data.dup)[0] + ' ' + err.data.message
                      : err.data.message,
                },
              },
              { position: POSITION.BOTTOM_RIGHT }
            );
          }
        });
    };

    expo.handlerRemove = (k_no) => {
      Swal.fire({
        title: 'Uyarı',
        text: 'Kayit Silinecektir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          store.dispatch('slideSil', k_no).then((res) => {
            if (res.data.success === true) {
              toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: `Silme`,
                    icon: 'Trash2Icon',
                    variant: 'danger',
                    text: `Silme başarılı.`,
                  },
                },
                { position: POSITION.BOTTOM_RIGHT }
              );
              expo.hideModal();
            }
          });
        }
      });
    };

    const prop = toRefs(props);

    watch(
      prop.openModal,
      (val) => {
        if (val == true) {
          expo.form.value = { ...props.updateData };
          expo.showModal();
        }
      },
      { deep: true }
    );

    return { ...expo };
  },
};
</script>

<style lang="scss" scoped></style>
