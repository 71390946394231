<template>
  <div>
    <b-card-actions
      ref="cardAction"
      title="Slider"
      icon="ImageIcon"
      :actionCollapse="false"
      :actionRefresh="true"
      :actionNewAdd="false"
      :collapsed="false"
      @refresh="handlerRefresh('cardAction')"
    >
      <template v-slot:body>
        <!-- <b-row v-if="slider.length == 0">
          <b-col>
            <b-alert variant="warning" class="text-center rounded-0" show>
              <h4 class="alert-heading">Opss !!</h4>
              <div class="alert-body">Gösterilecek Slider bulunamadı.</div>
            </b-alert>
          </b-col>
        </b-row> -->
        <b-row>
          <b-col>
            <input
              multiple
              type="file"
              ref="file"
              name="galeri"
              id="selectorImagesInput"
              accept="image/jpg,image/jpeg,image/png,image/gif"
              class="selector-images-input"
              @change="handlerOnSumbited"
            />
            <div class="selector-images-button" :disabled="true" @click="handlerSelectedImages">
              <feather-icon icon="FolderPlusIcon" size="48" v-if="!show" />
              <b-spinner v-else />
            </div>
          </b-col>
        </b-row>
        <draggable v-model="slider" handle=".draggable-task-handle" @end="handlerSlideChange">
          <transition-group type="transition" name="flip-list" class="row" tag="div">
            <b-col md="3" v-for="item in slider" :key="item.k_no">
              <slider-item :item="item" @refresh="handlerRefresh" class="draggable-task-handle" />
            </b-col>
          </transition-group>
        </draggable>
      </template>
    </b-card-actions>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import SliderItem from './component/sliderItem.vue';
import SliderEkle from './component/sliderEkle.vue';
import draggable from 'vuedraggable';
import store from '@/store';
import { ref, watch, computed, onMounted } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
import { POSITION } from 'vue-toastification';
export default {
  components: {
    BCardActions,
    SliderItem,
    SliderEkle,
    draggable,
  },

  setup(_, context) {
    const expo = {};
    const toast = useToast();
    expo.show = ref(false);
    expo.slider = ref([]);
    expo.diller = computed(() => store.getters.getDilAyarlari.diller);

    expo.handlerSelectedImages = () => {
      const file = document.getElementById('selectorImagesInput');
      file.click();
    };

    expo.handlerOnSumbited = async () => {
      expo.show.value = true;
      let formData = new FormData();
      let forms = context.refs.file.files;

      let icerik = {};
      expo.diller.value.forEach((dil) => {
        icerik[dil.lang] = { baslik: null, aciklama: null };
      });

      Object.keys(forms).map(function (key) {
        formData.append('slider', forms[key]);
      });

      formData.append('icerik', JSON.stringify(icerik));

      await store
        .dispatch('slideEkle', formData)
        .then((res) => {
          expo.show.value = false;
          expo.slider.value = store.getters.getSlider;
        })
        .catch((err) => {
          console.log(err);
          expo.show.value = false;
        });
    };

    expo.handlerGetData = async () => {
      expo.show.value = true;
      await store.dispatch('slideListele');
      expo.slider.value = await store.getters.getSlider;
      expo.show.value = false;
    };

    expo.handlerGetData();

    expo.handlerRefresh = () => {
      expo.show.value = true;
      expo.slider.value = store.getters.getSlider;
      expo.show.value = false;
    };

    expo.handlerRemoveImages = async (event) => {
      const data = { galeri_k_no: expo.k_no.value, ...event };
      await store.dispatch('sliderResimSil', data).then((res) => {
        if (res.data.success == true) {
          toast(
            {
              component: ToastificationContent,
              props: {
                title: `Bilgi`,
                icon: 'CheckSquareIcon',
                variant: 'danger',
                text: 'Resim Silme Başarılı',
              },
            },
            { position: POSITION.BOTTOM_RIGHT }
          );
          expo.data.value = store.getters.getGaleriler.find((x) => x.k_no == expo.k_no.value);
        }
      });
    };

    expo.handlerSlideChange = () => {
      store
        .dispatch('slideSiraGuncelle', { props: expo.slider.value })
        .then((res) => {
          if (res.data.success == true) {
            toast(
              {
                component: ToastificationContent,
                props: {
                  title: `Bilgi`,
                  icon: 'CheckSquareIcon',
                  variant: 'success',
                  text: 'Sıra güncellendi.',
                },
              },
              { position: POSITION.BOTTOM_RIGHT }
            );
          }
        })
        .catch((err) => {
          if (err.success == false) {
            toast(
              {
                component: ToastificationContent,
                props: {
                  title: `Hata`,
                  icon: 'CheckSquareIcon',
                  variant: 'danger',
                  text:
                    Object.keys(err.data.dup).length > 0
                      ? Object.keys(err.data.dup)[0] + ' ' + err.data.message
                      : err.data.message,
                },
              },
              { position: POSITION.BOTTOM_RIGHT }
            );
          }
        });
    };

    console.log(store.getters.getSlider);

    return { ...expo };
  },
};
</script>

<style lang="scss" scoped>
.selector-images-input {
  display: none;
}
.selector-images-button {
  position: relative;
  display: flex;
  width: 100%;
  flex-grow: 1;
  // max-width: 250px;
  height: auto;
  max-height: 100px;
  background-color: #ddd;
  align-items: center;
  justify-content: center;
  padding: 55px;
  margin-bottom: 10px;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background-color: darken(#dee, 9);
  }
}

.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
</style>
