<template>
  <b-row>
    <b-col md="5">
      <galeri-listesi @updateToImages="handlerResim($event)" />
    </b-col>
    <b-col md="7">
      <galeri-gorselleri :updateData="updateData" />
    </b-col>
  </b-row>
</template>

<script>
import { ref, computed } from '@vue/composition-api';
import GaleriListesi from './component/galeriListesi.vue';
import GaleriGorselleri from './component/galeriGorselleri.vue';
export default {
  components: {
    GaleriListesi,
    GaleriGorselleri,
  },
  setup() {
    const updateData = ref({});

    const handlerResim = (event) => {
      updateData.value = event;
    };
    return { handlerResim, updateData };
  },
};
</script>

<style lang="scss" scoped></style>
